<template>
    <div class="user-edit">
        <div class="main-h1">Редактирование пользователя</div>
        <div class="card">
            <div class="card-body">
                <table class="table">
                    <tr>
                        <td>Login:</td>
                        <td><input v-model="data.login" required></td>
                    </tr>
                    <tr>
                        <td>Город:</td>
                        <td>
                            <select v-model="data.city_id" :disabled="!$store.state.isAdmin()" required>
                                <option v-for="city in cities" :value="city.id">{{city.title}}</option>
                            </select>
                        </td>
                    </tr>
                    <tr>
                        <td>Организационно правовая форма:</td>
                        <td>
                            <select v-model="data.type" required>
                                <option v-for="type in userOrgTypes" :value="type">{{type}}</option>
                            </select>
                        </td>
                    </tr>
                    <tr>
                        <td>Имя:</td>
                        <td><input v-model="data.name" required></td>
                    </tr>
                    <tr>
                        <td>Email:</td>
                        <td><input v-model="data.email"></td>
                    </tr>
                    <tr>
                        <td>Номер телефона:</td>
                        <td><input v-model="data.phone"></td>
                    </tr>
                    <tr>
                        <td>Реквизиты:</td>
                        <td><textarea v-model="data.requisite"/></td>
                    </tr>
                    <tr v-if="$store.state.isAdmin() || $store.state.isImplementor()">
                        <td>Роли:</td>
                        <td>
                            <label v-for="role in roles.filter(item=>{return $store.state.isAdmin() || (item.name !== 'admin' && item.name !== 'implementor')})">
                                <input type="checkbox" :value="role.id" :checked="hasRole(role.id)" v-model="checkedRoles" name="newRoles"> {{role.title}}
                            </label>
                        </td>
                    </tr>
                    <tr v-if="hasRole(7)">
                        <td>Связанные истцы:</td>
                        <td>
                            <div class="relation-list">
                                <div v-for="item in data.agent_rel_claimants" class="item flex">
                                    <div class="name">{{item.name}}</div>
                                    <div class="delete" @click="deleteRelClaimant(this.$route.params.id,item.id)">
                                        <i class="material-icons">delete</i>
                                    </div>
                                </div>
                            </div>
                            <Select2 v-model="temp_relation_claimant"
                                     :options="claimants.map(item => {item.text = item.name; return item;})"
                                     :settings="{width: '100%'}"/>
                        </td>
                    </tr>
                    <tr>
                        <td>Смена пароля (если нужно)</td>
                        <td><input v-model="data.password" type="password"></td>
                    </tr>
                    <tr>
                        <td>Повторите пароль (если нужно)</td>
                        <td><input v-model="data.password2" type="password"></td>
                    </tr>
                    <tr>
                        <td></td>
                        <td>
                            <button class="btn blue" @click="sendData" :disabled="requestInProgress">Сохранить</button>
                        </td>
                    </tr>
                </table>
            </div>
        </div>
    </div>
</template>

<script>
import Select2 from "vue3-select2-component";
import userOrgTypes from "@/helpers/userOrgTypes";
export default {
    name: "userEdit",
    components: {Select2},
    data() {
        return {
            data:{
                agent_rel_claimants:[],
            },
            roles:[],
            requestInProgress:false,
            checkedRoles:[],
            temp_relation_claimant:0,
            claimants:[],
            userOrgTypes: userOrgTypes,
            cities:[],
        }
    },
    methods: {
        getData() {
            this.$api.get(`user/${this.$route.params.id}`).then(res => {
                if (res.data.success) {
                    this.data = res.data.data
                    this.checkedRoles = this.data.roles.map(role => role.id);
                    this.getRoles();
                }
            })
        },
        getRoles() {
            this.$api.get(`user/roles`).then(res => {
                if (res.data.success) {
                    this.roles = res.data.data
                }
            })
        },
        getCities() {
            this.$api.get(`city`).then(res => {
                if (res.data.success) {
                    this.cities = res.data.data
                }
            })
        },
        getClaimants() {
            this.$api.get(`user/claimants`).then(res => {
                if (res.data.success) {
                    this.claimants = res.data.data
                } else {
                    this.$store.commit('showNotify',{title:'Ошибка',content:res.data.error,type:'danger'});
                }
            })
        },
        hasRole(role_id) {
            return this.checkedRoles.includes(role_id) || this.checkedRoles.includes(role_id+'') || this.checkedRoles.includes(parseInt(role_id))
        },
        sendData(){
            this.requestInProgress = true;
            let data = Object.assign({},this.data);
            data.roles = this.checkedRoles;
            this.$api.post(`user/${this.$route.params.id}`,data).then(res => {
                this.requestInProgress = false;
                if (res.data.success) {
                    this.data = res.data.data;
                    this.$store.commit('showNotify',{title:'Сохранено',content:'Данные успешно сохранены.',type:'success'})
                } else {
                    this.data = res.data.data;
                    this.$store.commit('showNotify',{title:'Ошибка',content:res.data.error,type:'danger'})
                }
            })
        },
        addRelClaimant(agent_id,claimant_id) {
            this.$api.post(`user/${agent_id}/rel_claimants`,{claimant_id:claimant_id}).then(res => {
                this.requestInProgress = false;
                if (res.data.success) {
                    this.$store.commit('showNotify',{title:'Сохранено',content:'Данные успешно сохранены.',type:'success'});
                } else {
                    this.$store.commit('showNotify',{title:'Ошибка',content:res.data.error,type:'danger'})
                }
                this.getRelClaimant(agent_id);
            })
        },
        deleteRelClaimant(agent_id,claimant_id) {
            this.$api.delete(`user/${agent_id}/rel_claimants/${claimant_id}`).then(res => {
                this.requestInProgress = false;
                if (res.data.success) {
                    this.$store.commit('showNotify',{title:'Сохранено',content:'Данные успешно сохранены.',type:'success'});
                } else {
                    this.$store.commit('showNotify',{title:'Ошибка',content:res.data.error,type:'danger'})
                }
                this.getRelClaimant(agent_id);
            })
        },
        getRelClaimant(user_id) {
            this.$api.get(`user/${user_id}/rel_claimants`).then(res => {
                this.requestInProgress = false;
                if (res.data.success) {
                    this.data.agent_rel_claimants = res.data.data;
                } else {
                    this.$store.commit('showNotify',{title:'Ошибка',content:res.data.error,type:'danger'})
                }
            })
        },
    },
    created() {
        this.getData();
        this.getClaimants();
        this.getRelClaimant(this.$route.params.id);
        this.getCities();
    },
    watch: {
        temp_relation_claimant: function () {this.addRelClaimant(this.$route.params.id,this.temp_relation_claimant)},
    }
}
</script>

<style lang="scss" scoped>
@import "src/assets/scss/variables";
.relation-list {
    margin: 0 0 10px;
    .item {
        padding: 5px 20px;
        align-items: center;
        transition: 0.2s;
        &:hover {
            background: $cl-border-focus;
        }
        .delete {
            cursor: pointer;
        }
    }
}
</style>